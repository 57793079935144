<template>
  <TagbyDangerModal
    :visible="visible"
    title="배너 삭제"
    @ok="$emit('ok')"
    @hidden="$emit('hidden')"
  >
    <template #body>
      배너를 삭제하시겠습니까?
    </template>
  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

  },
})
</script>
