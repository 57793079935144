<template>
  <BCard title="배너 정보">
    <BRow>
      <BCol cols="4">
        <BFormGroup label="IDX">
          <BFormInput
            :value="banner.idx"
            disabled
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="START_AT">
          <TagbyDatetimeInputV2
            v-model="banner.start_at"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="END_AT">
          <TagbyDatetimeInputV2
            v-model="banner.end_at"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol cols="4">
        <BFormGroup label="ORDER_SEQ">
          <vSelect
            v-model="banner.order_seq"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="orderSeqOptions"
            :reduce="option => option.value"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="REDIRECT_TYPE">
          <vSelect
            v-model="banner.redirect_type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="redirectTypeOptions"
            :reduce="option => option.value"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup
          v-if="banner.redirect_type === 'campaign_detail'"
          label="CAMPAIGN_IDX"
        >
          <BFormInput
            v-model="banner.redirect_value"
          />
        </BFormGroup>
        <BFormGroup
          v-else-if="banner.redirect_type === 'link'"
          label="LINK"
        >
          <BFormInput
            v-model="banner.redirect_value"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol cols="4">
        <BFormGroup label="STATE">
          <vSelect
            v-model="banner.state"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="stateOptions"
            :reduce="option => option.value"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="TITLE">
          <BFormTextarea
            v-model="banner.header_1"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="TEXT">
          <BFormTextarea
            v-model="banner.header_2"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="IMG" />
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BImg
          v-if="banner.img_file != null"
          :src="banner.img_file.url"
          fluid
        />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BImg,
    vSelect,
    TagbyDatetimeInputV2,
  },
  props: {
    banner: {
      type: Object,
      default: () => ({}),
    },
    redirectTypeOptions: {
      type: Array,
      default: () => ([]),
    },
    orderSeqOptions: {
      type: Array,
      default: () => ([]),
    },
    stateOptions: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
