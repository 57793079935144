<template>
  <div>
    <BAlert
      variant="danger"
      :show="hasLoadError"
    >
      <h4 class="alert-heading">
        Error fetching banner data
      </h4>
      <div class="alert-body">
        No banner found with this banner id. Check
        <BLink
          class="alert-link"
          :to="{ name: 'app-widget-banner-list'}"
        >
          Banner List
        </BLink>
        for other banners.
      </div>
    </BAlert>
    <BRow>
      <BCol
        cols="12"
        xl="8"
        lg="8"
        md="7"
      >
        <BannerInfoCard
          :banner="banner"
          :redirectTypeOptions="redirectTypeOptions"
          :orderSeqOptions="orderSeqOptions"
          :stateOptions="stateOptions"
        />
      </BCol>

      <BCol
        cols="12"
        xl="4"
        lg="4"
        md="5"
      >
        <ActionCard
          :banner="banner"
          :isSaving="isSaving"
          @update="update"
          @turnOnDeleteModal="turnOnDeleteModal"
        />
      </BCol>
    </BRow>

    <DeleteModal
      :visible="visibleDeleteModal"
      @ok="deleteBanner"
      @hidden="turnOffDeleteModal"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import viewModel from './viewModel'
import BannerInfoCard from './components/BannerInfoCard.vue'
import ActionCard from './components/ActionCard.vue'
import DeleteModal from './components/DeleteModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    BannerInfoCard,
    ActionCard,
    DeleteModal,
  },
  setup() {
    return {
      ...viewModel(),
    }
  },
}
</script>
