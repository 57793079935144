import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import store from '@/store'
import router from '@/router'
import useToast from '@/utils/toast'
import useResolveValue from '@/utils/resolveValue'

import storeModule from './storeModule'

export default function () {
  const APP_STORE_MODULE_NAME = 'app-widget-banner-detail'

  const { makeToast } = useToast()
  const {
    resolveUtcStartDateTime,
    resolveUtcEndDateTime,
  } = useResolveValue()
  const storeDispatch = (name, params) => store.dispatch(`${APP_STORE_MODULE_NAME}/${name}`, params)

  const orderSeqOptions = ref([])

  const fetchOrderSeqList = () => {
    storeDispatch('fetchOrderSeqList').then(response => {
      const resData = response.data.data
      orderSeqOptions.value = resData.map(o => ({
        label: o.display_order_seq,
        value: o.order_seq,
      }))
    }).catch(() => {
      makeToast('danger', '순서 리스트를 가져오는데 실패했습니다')
    }).finally(() => {
    })
  }

  const banner = ref()
  const timelines = ref()
  const hasLoadError = ref(false)
  const redirectTypeOptions = [
    { label: '캠페인 상세', value: 'campaign_detail' },
    { label: '링크', value: 'link' },
    { label: '최신 등록 상품', value: 'recent_registered_item' },
  ]

  const isSaving = ref(false)

  const resolveRedirectType = redirectPath => {
    if (redirectPath == null || redirectPath === {}) return null
    const redirectType = redirectPath?.route_name?.[0]
    if (redirectType === 'new_campaign_detail') return 'campaign_detail'
    if (redirectType === 'RecentRegisteredItemScreen') return 'recent_registered_item'
    return 'link'
  }
  const resolveRedirectValue = redirectPath => {
    const redirectType = resolveRedirectType(redirectPath)
    if (redirectType === 'campaign_detail') return redirectPath?.param?.[0]?.hero_key
    if (redirectType === 'recent_registered_item') return null
    if (redirectType === 'link') return redirectPath?.route_name?.[0]
    return null
  }
  const resolveOrderSeq = orderSeq => orderSeqOptions.value.find(o => o.value === orderSeq)?.value

  const fetchDetail = () => storeDispatch('fetchDetail', {
    idx: router.currentRoute.params.idx,
  }).then(response => {
    const resData = response.data.data
    orderSeqOptions.value = resData.order_seq_list.map(o => ({
      label: o.display_order_seq,
      value: o.order_seq,
    }))
    banner.value = {
      ...resData.banner,
      redirect_type: resolveRedirectType(resData.banner.redirect_path),
      redirect_value: resolveRedirectValue(resData.banner.redirect_path),
      order_seq: resolveOrderSeq(resData.banner.order_seq),
    }
  }).catch(() => {
    hasLoadError.value = true
  }).finally(() => {
  })

  const fetchTimelines = () => {
    storeDispatch('fetchTimelines', {
      idx: router.currentRoute.params.idx,
    }).then(response => {
      timelines.value = response.data.data
    }).catch(() => {
      makeToast('danger', '타임라인을 가져오는데 실패했습니다')
    }).finally(() => {
    })
  }

  const update = () => {
    isSaving.value = true
    const params = {
      idx: router.currentRoute.params.idx,
      header_1: banner.value.header_1,
      header_2: banner.value.header_2,
      order_seq: banner.value.order_seq,
      start_at: resolveUtcStartDateTime(banner.value.start_at),
      end_at: resolveUtcEndDateTime(banner.value.end_at),
      redirect_type: banner.value.redirect_type,
      redirect_value: banner.value.redirect_value,
      state: banner.value.state,
    }

    storeDispatch('update', params)
      .then(() => {
        fetchDetail()
        fetchTimelines()
        makeToast('primary', '저장에 성공했습니다.')
      }).catch(() => {
        makeToast('danger', '저장에 실패했습니다.')
      }).finally(() => {
        isSaving.value = false
      })
  }

  const visibleDeleteModal = ref(false)
  const turnOnDeleteModal = () => {
    visibleDeleteModal.value = true
  }
  const turnOffDeleteModal = () => {
    visibleDeleteModal.value = false
  }
  const deleteBanner = () => {
    storeDispatch('delete', {
      idx: router.currentRoute.params.idx,
    }).then(() => {
      fetchDetail()
      fetchTimelines()
      makeToast('primary', '삭제에 성공했습니다.')
    }).catch(() => {
      makeToast('danger', '삭제에 실패했습니다.')
    }).finally(() => {
      isSaving.value = false
    })
  }

  const memoContent = ref()
  const visibleMemo = ref(false)
  const turnOnMemo = () => {
    visibleMemo.value = true
    memoContent.value = null
  }
  const turnOffMemo = () => {
    visibleMemo.value = false
  }
  const isSavingMemo = ref(false)
  const inputMemoContent = value => {
    memoContent.value = value
  }
  const createMemo = () => {
    isSavingMemo.value = true
    storeDispatch('createMemo', {
      idx: router.currentRoute.params.idx,
      content: memoContent.value,
    }).then(() => {
      makeToast('primary', '메모 생성에 성공했습니다')
      fetchTimelines()
    }).catch(() => {
      makeToast('primary', '메모 생성에 성공했습니다')
    }).finally(() => {
      isSavingMemo.value = false
      turnOffMemo()
    })
  }

  const stateOptions = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ]

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)
    fetchDetail()
    fetchTimelines()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  return {
    banner,
    timelines,
    hasLoadError,
    isSaving,
    update,

    redirectTypeOptions,
    orderSeqOptions,

    visibleDeleteModal,
    turnOnDeleteModal,
    turnOffDeleteModal,
    deleteBanner,

    memoContent,
    visibleMemo,
    turnOnMemo,
    turnOffMemo,
    isSavingMemo,
    inputMemoContent,
    createMemo,

    stateOptions,
  }
}
