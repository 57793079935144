<template>
  <BCard>
    <BRow>
      <BCol
        cols="12"
        xl="6"
        class="mb-1"
      >
        <BButton
          variant="outline-primary"
          :disabled="isSaving"
          block
          @click="$emit('update')"
        >
          <BSpinner
            v-if="isSaving"
            small
          />
          저장
        </BButton>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  props: {
    banner: {
      type: Object,
      default: () => ({}),
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  emtis: [
    'create',
  ],
}
</script>
