import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDetail(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/banner/${idx}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrderSeqList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/banner/order_seq_list/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTimelines(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/banner/${idx}/timelines/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    update(ctx, { idx, ...params }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/banner/${idx}/update/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete(ctx, { idx, ...params }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/banner/${idx}/delete/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
